import "@fontsource/manrope";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";
import "./src/styles/global.scss";

import React from "react";
import { UserProvider } from "./src/components/contexts/UserContext";
import MiddlewareRoute from "./src/components/middleware/MiddlewareRoute";
import { singularSdk, SingularConfig } from "singular-sdk";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { analytics } from "./src/util/CustomAnalitics";

const configSingular = new SingularConfig(
  process.env.SINGULAR_PK,
  process.env.SINGULAR_SK,
  process.env.SINGULAR_PID
);

export const wrapPageElement = ({ element, props }) => {
  const isStampRoute = props.location.pathname.startsWith("/stamp");
  const isAccountRoute = props.location.pathname.startsWith("/account");
  const isMerchantRoute = props.location.pathname.startsWith("/merchant");

  if (props.location.pathname !== "/") {
    singularSdk.init(configSingular);
  }

  if (isStampRoute || isAccountRoute || isMerchantRoute)
    return (
      <UserProvider>
        <MiddlewareRoute>{element}</MiddlewareRoute>
      </UserProvider>
    );
  return element;
};

export const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
);

export const onInitialClientRender = () => {
  analytics.page();
  
  const storedUser = JSON.parse(localStorage.getItem("user"));
  if (storedUser) {
    analytics.identify(storedUser.user.uuid, {
      name:
        storedUser.user.role === "customer"
          ? storedUser.customer.fullName
          : storedUser.employee.fullName,
      email: storedUser.user.email,
      phone: storedUser.user.phone,
    });
  }
};

export const onRouteUpdate = ({ location }) => {
  analytics.page({
    path: location.pathname,
    search: location.search,
    hash: location.hash,
  });

  const storedUser = JSON.parse(localStorage.getItem("user"));
  if (storedUser) {
    analytics.identify(storedUser.user.uuid, {
      name:
        storedUser.user.role === "customer"
          ? storedUser.customer.fullName
          : storedUser.employee.fullName,
      email: storedUser.user.email,
      phone: storedUser.user.phone,
    });
  }
};
