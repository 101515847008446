exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-detail-tsx": () => import("./../../../src/pages/account/detail.tsx" /* webpackChunkName: "component---src-pages-account-detail-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-legal-tsx": () => import("./../../../src/pages/account/legal.tsx" /* webpackChunkName: "component---src-pages-account-legal-tsx" */),
  "component---src-pages-account-setting-tsx": () => import("./../../../src/pages/account/setting.tsx" /* webpackChunkName: "component---src-pages-account-setting-tsx" */),
  "component---src-pages-employee-tsx": () => import("./../../../src/pages/employee.tsx" /* webpackChunkName: "component---src-pages-employee-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-merchant-[uuid]-tsx": () => import("./../../../src/pages/merchant/[uuid].tsx" /* webpackChunkName: "component---src-pages-merchant-[uuid]-tsx" */),
  "component---src-pages-merchant-index-tsx": () => import("./../../../src/pages/merchant/index.tsx" /* webpackChunkName: "component---src-pages-merchant-index-tsx" */),
  "component---src-pages-merchant-reedem-[uuid]-tsx": () => import("./../../../src/pages/merchant/reedem/[uuid].tsx" /* webpackChunkName: "component---src-pages-merchant-reedem-[uuid]-tsx" */),
  "component---src-pages-merchant-scan-tsx": () => import("./../../../src/pages/merchant/scan.tsx" /* webpackChunkName: "component---src-pages-merchant-scan-tsx" */),
  "component---src-pages-password-[uuid]-tsx": () => import("./../../../src/pages/password/[uuid].tsx" /* webpackChunkName: "component---src-pages-password-[uuid]-tsx" */),
  "component---src-pages-password-index-tsx": () => import("./../../../src/pages/password/index.tsx" /* webpackChunkName: "component---src-pages-password-index-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-stamp-[uuid]-tsx": () => import("./../../../src/pages/stamp/[uuid].tsx" /* webpackChunkName: "component---src-pages-stamp-[uuid]-tsx" */),
  "component---src-pages-stamp-code-tsx": () => import("./../../../src/pages/stamp/code.tsx" /* webpackChunkName: "component---src-pages-stamp-code-tsx" */),
  "component---src-pages-stamp-index-tsx": () => import("./../../../src/pages/stamp/index.tsx" /* webpackChunkName: "component---src-pages-stamp-index-tsx" */),
  "component---src-pages-stamp-rewards-tsx": () => import("./../../../src/pages/stamp/rewards.tsx" /* webpackChunkName: "component---src-pages-stamp-rewards-tsx" */),
  "component---src-pages-stamp-scan-tsx": () => import("./../../../src/pages/stamp/scan.tsx" /* webpackChunkName: "component---src-pages-stamp-scan-tsx" */),
  "component---src-pages-stamp-search-tsx": () => import("./../../../src/pages/stamp/search.tsx" /* webpackChunkName: "component---src-pages-stamp-search-tsx" */)
}

