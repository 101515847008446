import { useEffect } from "react";
import { ApiMe } from "../service/api";

export const useGetMe = (setUser: (user: IUser) => void | null) => {
  const getMe = async () => {
    if (localStorage.getItem("user")) {
      const data = JSON.parse(localStorage.getItem("user") ?? "");
      setUser(data);
    } else {
      const { data } = await ApiMe();
      localStorage.setItem("user", JSON.stringify(data));
      setUser(data);
    }
  };

  useEffect(() => {
    getMe();
  }, []);
};

export const generateProfileText = (fullName: string) => {
  let profileText = "";
  if (fullName) {
    if (fullName.includes(" ")) {
      const names = fullName.split(" ");
      profileText = `${names[0].substring(0, 1)}${names[1].substring(0, 1)}`;
    } else {
      profileText = fullName.substring(0, 2);
    }
  }
  return profileText;
};

export const parseJwt = (token: string) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
