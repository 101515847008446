export const ApiSignUp = async (values: {
  email: string;
  password: string;
  fullName: string;
  phone: string;
  otp: string;
}) => {
  const nValues = {
    user: {
      email: values.email,
      password: values.password,
      phone: values.phone.replace(" ", ""),
    },
    customer: { fullName: values.fullName },
    otp: values.otp,
  };

  const response = await fetch(`${process.env.API_URL}/auth/sign-up`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(nValues),
  });

  return await response.json();
};

export const ApiSignIn = async (values: {
  username: string;
  password?: string;
  role?: string;
  otp?: string;
}) => {
  const response = await fetch(`${process.env.API_URL}/auth/sign-in`, {
    method: "POST",
    headers: { accept: "application/json", "Content-type": "application/json" },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiEmailValid = async (values: { email: string }) => {
  const response = await fetch(`${process.env.API_URL}/auth/verify`, {
    method: "POST",
    headers: { accept: "application/json", "Content-type": "application/json" },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiMe = async () => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/auth/me`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiMeDelete = async () => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/auth/me`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiSendMsg = async (values: { phone: string; role: string }) => {
  const response = await fetch(`${process.env.API_URL}/auth/otp`, {
    method: "POST",
    headers: { accept: "application/json", "Content-type": "application/json" },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiForgotPass = async (values: { email: string }) => {
  const response = await fetch(`${process.env.API_URL}/auth/password/forgot`, {
    method: "POST",
    headers: { accept: "application/json", "Content-type": "application/json" },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiChangePass = async (values: any) => {
  const response = await fetch(`${process.env.API_URL}/auth/password/restore`, {
    method: "POST",
    headers: { accept: "application/json", "Content-type": "application/json" },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiEditUser = async (values: {
  fullName?: string;
  email?: string;
  phone?: string;
  birthDate?: string;
}) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/auth/me`, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiStamps = async (query: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/card${query ? "?" + query : ""}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiStamp = async (uuid: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/card/${uuid}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiBusiness = async (query: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/business${query ? "?" + query : ""}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiBusinessShow = async (uuid: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/business/${uuid}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiStatistics = async () => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/dashboard/statistics`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiJoinBirthdateClub = async (values: { birthdate: string }) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/birthday-club`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiRewards = async (query: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/customer/reward${query ? "?" + query : ""}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiCountRewards = async () => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/customer/reward/counter`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiGenerateStampQr = async (values: {
  card: string;
  branch: string;
  quantity: number;
}) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/employee/qr-code`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiReciveStampQr = async (uuid: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/customer/qr-code/${uuid}`,
    { method: "POST", headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiGenerateRewardQr = async (values: { reward: string }) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/customer/qr-code`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(values),
  });

  return await response.json();
};

export const ApiStampJoin = async (uuid: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/card/${uuid}/join`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiPastRewards = async () => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(`${process.env.API_URL}/customer/reward/past`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return await response.json();
};

export const ApiRewardShow = async (uuid: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/employee/qr-code/${uuid}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};

export const ApiRewardRedeem = async (uuid: string, branch: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/employee/qr-code/${uuid}`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ branch }),
    }
  );

  return await response.json();
};

export const ApiSearchQrCode = async (link: string) => {
  const token = localStorage.getItem("token") ?? "";
  const response = await fetch(
    `${process.env.API_URL}/customer/qr-code/link?link=${encodeURIComponent(link)}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return await response.json();
};
