import React, { FC, ReactNode } from "react";
import { parseJwt } from "../../util/CustomFunctions";

interface RouteProps {
  children: ReactNode;
}

const MiddlewareRoute: FC<RouteProps> = ({ children }) => {
  if (typeof window === "undefined") return;
  const token = localStorage.getItem("token");

  if (!token) {
    typeof window !== "undefined" ? localStorage.clear() : false;
    window.location.href = "/";
    return null;
  }

  const pToken = parseJwt(token);
  if (pToken.exp < Math.floor(Date.now() / 1000)) {
    typeof window !== "undefined" ? localStorage.clear() : false;
    window.location.href = "/";
    return null;
  }

  return <>{children}</>;
};

export default MiddlewareRoute;
